var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "Modal",
        {
          attrs: { title: "Help" },
          on: {
            close: function($event) {
              _vm.showHelpModal = false
            }
          },
          model: {
            value: _vm.showHelpModal,
            callback: function($$v) {
              _vm.showHelpModal = $$v
            },
            expression: "showHelpModal"
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-12" },
              [
                _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                  _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                  _vm._v("Comment définir Le flux d'informations ? ")
                ]),
                _c("p", { staticClass: "card-text" }, [
                  _vm._v(
                    " Principalement dans cette version, le flux d'informations ici définit les personnes concerné par les notifications des différentes opérations. "
                  )
                ]),
                _c("br"),
                _c("ol", [
                  _c("li", [
                    _c("p", [
                      _vm._v("Vous pouvez sélectionner un ou plusieurs rôles.")
                    ])
                  ]),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        "Chaque rôle peut contenir un group d'utilisateurs."
                      )
                    ])
                  ]),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        " Pour chaque rôle selectionné, chaque utilisateur sera informé quand une requête est crée. "
                      )
                    ])
                  ]),
                  _c("li", [
                    _c("p", [
                      _vm._v(
                        " Les utilisateurs qui ont crée les opérations seront notifiés par défaut, avec leurs supérieurs directes. "
                      )
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "b-tabs",
                  {
                    attrs: {
                      pills: "",
                      vertical: "",
                      "nav-class": "p-0",
                      "nav-wrapper-class": "col-sm-2",
                      "content-class": "pt-0 px-2 text-muted"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Contrat",
                          active: "",
                          "title-item-class": "mb-2"
                        }
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submitContractNotifsConfig($event)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Consommations")
                                ]),
                                _c("hr"),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("1ère alerte est déclanchée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.contract
                                                .ctrCnsmFirstAlertNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.contract,
                                                "ctrCnsmFirstAlertNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.contract\n                                .ctrCnsmFirstAlertNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("2ème alerte est déclanchée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.contract
                                                .ctrCnsmSecondAlertNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.contract,
                                                "ctrCnsmSecondAlertNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.contract\n                                .ctrCnsmSecondAlertNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("3ème alerte est déclanchée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.contract
                                                .ctrCnsmFinalAlertNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.contract,
                                                "ctrCnsmFinalAlertNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.contract\n                                .ctrCnsmFinalAlertNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Délais du contrat")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("1ère alerte est déclanchée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.contract
                                                .ctrEndDateFirstAlertNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.contract,
                                                "ctrEndDateFirstAlertNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.contract\n                                .ctrEndDateFirstAlertNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("2ème alerte est déclanchée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.contract
                                                .ctrEndDateSecondAlertNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.contract,
                                                "ctrEndDateSecondAlertNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.contract\n                                .ctrEndDateSecondAlertNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("3ème alerte est déclanchée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.contract
                                                .ctrEndDateFinalAlertNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.contract,
                                                "ctrEndDateFinalAlertNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.contract\n                                .ctrEndDateFinalAlertNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary float-right"
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-save" }),
                                    _vm._v(" Enregistrer ")
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "ODS", "title-item-class": "mb-2" } },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submitSoNotifsConfig($event)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Restauration")
                                ]),
                                _c("hr"),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est généré")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .restSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "restSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.restSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est aprouvé")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .approvedRestSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "approvedRestSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.approvedRestSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(" Un ODS est contrôllé ")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .controlledRestSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "controlledRestSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.controlledRestSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Hébergement")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est générée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .hostSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "hostSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "notifications.so.hostSoNotifiableRoles"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est approuvée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .approvedHostSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "approvedHostSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.approvedHostSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est contrôllé")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .controlledHostSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "controlledHostSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.controlledHostSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Espace Vert")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est générée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .gsSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "gsSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "notifications.so.gsSoNotifiableRoles"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est approuvé")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .approvedGsSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "approvedGsSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.approvedGsSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est contrôllé")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .controlledGsSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "controlledGsSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.controlledGsSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Opérations 3D")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est générée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .thrdSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "thrdSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "notifications.so.thrdSoNotifiableRoles"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est approuvée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .approvedThrdSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "approvedThrdSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.approvedThrdSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un ODS est contrôllé")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.so
                                                .controlledThrdSoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.so,
                                                "controlledThrdSoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.so.controlledThrdSoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary float-right"
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-save" }),
                                    _vm._v(" Enregistrer ")
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Hébergement",
                          "title-item-class": "mb-2"
                        }
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submitHostingNotifsConfig($event)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Réservations")
                                ]),
                                _c("hr"),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une demande est généré")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.hosting
                                                .bookingNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.hosting,
                                                "bookingNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.hosting.bookingNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(
                                            "Une réservation est approuvée"
                                          )
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.hosting
                                                .approvedBookingNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.hosting,
                                                "approvedBookingNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.hosting.approvedBookingNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(
                                            " Une réservation est rejetée "
                                          )
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.hosting
                                                .rejectedBookingNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.hosting,
                                                "rejectedBookingNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.hosting.rejectedBookingNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4 mt-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(" CHECK-IN ")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.hosting
                                                .checkinBookingNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.hosting,
                                                "checkinBookingNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.hosting.checkinBookingNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4 mt-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(" CHECK-OUT ")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.hosting
                                                .checkoutdBookingNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.hosting,
                                                "checkoutdBookingNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.hosting.checkoutdBookingNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary float-right"
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-save" }),
                                    _vm._v(" Enregistrer ")
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: { title: "Requêtes", "title-item-class": "mb-2" }
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submitRequestsNotifsConfig($event)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Requêtes Maintenance")
                                ]),
                                _c("hr"),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est crée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .mrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "mrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.mrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est validée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .approvedMrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "approvedMrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.approvedMrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(" Une requête est rejetée ")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .rejectedMrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "rejectedMrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.rejectedMrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Ordre de Maintenance")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("un OM est crée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .moNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "moNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "notifications.requests.moNotifiableRoles"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("un OM est approuvé")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .approvedMoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "approvedMoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.approvedMoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("un OM est rejeté")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .rejectedMoNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "rejectedMoNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.rejectedMoNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Requêtes Prise en charge")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est crée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .srNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "srNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "notifications.requests.srNotifiableRoles"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est approuvée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .approvedSrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "approvedSrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.approvedSrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est rejetée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .rejectedSrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "rejectedSrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.rejectedSrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v(" Requêtes Demande de dotations ")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est crée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .drNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "drNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "notifications.requests.drNotifiableRoles"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est approuvée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .approvedDrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "approvedDrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.approvedDrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est rejetée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .rejectedDrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "rejectedDrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.rejectedDrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v(" Requêtes Demande de repas emporté ")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est crée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .tamrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "tamrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests.tamrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est approuvée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .approvedTamrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "approvedTamrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests\n                                .approvedTamrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une requête est rejetée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.requests
                                                .rejectedTamrNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.requests,
                                                "rejectedTamrNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.requests\n                                .rejectedTamrNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary float-right"
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-save" }),
                                    _vm._v(" Enregistrer ")
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Facturation",
                          "title-item-class": "mb-2"
                        }
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submitInvoicingNotifsConfig($event)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Attachements")
                                ]),
                                _c("hr"),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un attachement est généré")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .attNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "attNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.attNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Un attachement est aprouvé")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .approvedAttNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "approvedAttNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.approvedAttNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(" Un attachement est bloquée ")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .blockedAttNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "blockedAttNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.blockedAttNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4 mt-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v(" Un attachement est clôturé ")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .closedAttNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "closedAttNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.closedAttNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-12 " }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Factures prestataire")
                                ]),
                                _c("h5", [_vm._v("Qui sera notifié quand:")]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une facture est générée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .invNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "invNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "notifications.invoicing.invNotifiableRoles"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une facture est approuvée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .approvedInvNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "approvedInvNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.approvedInvNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une facture est annulée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .cancelledInvNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "cancelledInvNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.cancelledInvNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4 mt-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une facture est payée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .invPaymentRegNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "invPaymentRegNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.invPaymentRegNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-md-4 mt-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-grou" },
                                      [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Une facture est clôturée")
                                        ]),
                                        _c("multiselect", {
                                          attrs: {
                                            searchable: true,
                                            "track-by": "id",
                                            multiple: true,
                                            label: "name",
                                            options: _vm.rolesList,
                                            placeholder: "",
                                            "select-label": "",
                                            "allow-empty": false
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "singleType",
                                              fn: function(ref) {
                                                var type = ref.type
                                                return [
                                                  _vm._v(_vm._s(type.name))
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value:
                                              _vm.notifications.invoicing
                                                .closedInvNotifiableRoles,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.notifications.invoicing,
                                                "closedInvNotifiableRoles",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                              notifications.invoicing.closedInvNotifiableRoles\n                            "
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary float-right"
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-save" }),
                                    _vm._v(" Enregistrer ")
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }