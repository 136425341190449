<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

import { fetchSettings, fetchRolesListApi } from "@/api/common";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Notifications & Workflow",
    meta: [{ name: "description", content: appConfig.description }],
  },
  // validations: {
  //   config: {
  //     requests: {
  //       mr_ref_prefix: { required },
  //       mo_ref_prefix: { required },
  //       vs_ref_prefix: { required },
  //       dr_ref_prefix: { required },
  //       tamr_ref_prefix: { required },
  //       mr_ref_format: { required },
  //       mo_ref_format: { required },
  //       vs_ref_format: { required },
  //       dr_ref_format: { required },
  //       tamr_ref_format: { required },
  //       mr_ref_position: { required, numeric },
  //       mo_ref_position: { required, numeric },
  //       vs_ref_position: { required, numeric },
  //       dr_ref_position: { required, numeric },
  //       tamr_ref_position: { required, numeric },
  //     },
  //     hosting: {
  //       booking_prefix: { required },
  //       booking_format: { required },
  //       booking_position: { required, numeric },
  //     },

  //     ods: {
  //       rest_ods_prefix: { required },
  //       maint_ods_prefix: { required },
  //       rest_ods_format: { required },
  //       maint_ods_format: { required },
  //       rest_ods_position: { required, numeric },
  //       maint_ods_position: { required, numeric },
  //     },

  //     invoicing: {
  //       invoice_prefix: { required },
  //       payment_prefix: { required },
  //       invoice_format: { required },
  //       payment_format: { required },
  //       invoice_position: { required, numeric },
  //       payment_position: { required, numeric },
  //     },
  //   },
  // },
  components: { Layout, PageHeader, Multiselect },
  import: { fetchSettings, fetchRolesListApi },

  mounted() {
    this.populateCompanySettings();
    this.fetchRolesList();
  },

  methods: {
    fetchRolesList() {
      fetchRolesListApi().then((res) => {
        console.log(res);
        this.rolesList = res.data.original.data;
      });
    },

    populateCompanySettings() {
      fetchSettings()
        .then(({ data }) => {
          var configs = data.data;
          if(configs.ctrCnsmFirstAlertNotifiableRoles) this.notifications.contract.ctrCnsmFirstAlertNotifiableRoles = JSON.parse(configs.ctrCnsmFirstAlertNotifiableRoles);
          if(configs.ctrCnsmSecondAlertNotifiableRoles) this.notifications.contract.ctrCnsmSecondAlertNotifiableRoles = JSON.parse(configs.ctrCnsmSecondAlertNotifiableRoles);
          if(configs.ctrCnsmFinalAlertNotifiableRoles) this.notifications.contract.ctrCnsmFinalAlertNotifiableRoles = JSON.parse(configs.ctrCnsmFinalAlertNotifiableRoles);
          if(configs.ctrEndDateFirstAlertNotifiableRoles) this.notifications.contract.ctrEndDateFirstAlertNotifiableRoles = JSON.parse(configs.ctrEndDateFirstAlertNotifiableRoles);
          if(configs.ctrEndDateSecondAlertNotifiableRoles) this.notifications.contract.ctrEndDateSecondAlertNotifiableRoles = JSON.parse(configs.ctrEndDateSecondAlertNotifiableRoles);
          if(configs.ctrEndDateFinalAlertNotifiableRoles) this.notifications.contract.ctrEndDateFinalAlertNotifiableRoles = JSON.parse(configs.ctrEndDateFinalAlertNotifiableRoles);

          if(configs.mrNotifiableRoles) this.notifications.requests.mrNotifiableRoles = JSON.parse(configs.mrNotifiableRoles);
          if(configs.approvedMrNotifiableRoles) this.notifications.requests.approvedMrNotifiableRoles = JSON.parse(configs.approvedMrNotifiableRoles);
          if(configs.rejectedMrNotifiableRoles) this.notifications.requests.rejectedMrNotifiableRoles = JSON.parse(configs.rejectedMrNotifiableRoles);
          if(configs.moNotifiableRoles) this.notifications.requests.moNotifiableRoles = JSON.parse(configs.moNotifiableRoles);
          if(configs.approvedMoNotifiableRoles) this.notifications.requests.approvedMoNotifiableRoles = JSON.parse(configs.approvedMoNotifiableRoles);
          if(configs.rejectedMoNotifiableRoles) this.notifications.requests.rejectedMoNotifiableRoles = JSON.parse(configs.rejectedMoNotifiableRoles);
          if(configs.srNotifiableRoles) this.notifications.requests.srNotifiableRoles = JSON.parse(configs.srNotifiableRoles);
          if(configs.approvedSrNotifiableRoles) this.notifications.requests.approvedSrNotifiableRoles = JSON.parse(configs.approvedSrNotifiableRoles);
          if(configs.rejectedSrNotifiableRoles) this.notifications.requests.rejectedSrNotifiableRoles = JSON.parse(configs.rejectedSrNotifiableRoles);
          if(configs.drNotifiableRoles) this.notifications.requests.drNotifiableRoles = JSON.parse(configs.drNotifiableRoles);
          if(configs.approvedDrNotifiableRoles) this.notifications.requests.approvedDrNotifiableRoles = JSON.parse(configs.approvedDrNotifiableRoles);
          if(configs.rejectedDrNotifiableRoles) this.notifications.requests.rejectedDrNotifiableRoles = JSON.parse(configs.rejectedDrNotifiableRoles);
          if(configs.tamrNotifiableRoles) this.notifications.requests.tamrNotifiableRoles = JSON.parse(configs.tamrNotifiableRoles);
          if(configs.approvedTamrNotifiableRoles) this.notifications.requests.approvedTamrNotifiableRoles = JSON.parse(configs.approvedTamrNotifiableRoles);
          if(configs.rejectedTamrNotifiableRoles) this.notifications.requests.rejectedTamrNotifiableRoles = JSON.parse(configs.rejectedTamrNotifiableRoles);

          if(configs.restSoNotifiableRoles) this.notifications.so.restSoNotifiableRoles = JSON.parse(configs.restSoNotifiableRoles);
          if(configs.approvedRestSoNotifiableRoles) this.notifications.so.approvedRestSoNotifiableRoles = JSON.parse(configs.approvedRestSoNotifiableRoles);
          if(configs.controlledRestSoNotifiableRoles) this.notifications.so.controlledRestSoNotifiableRoles = JSON.parse(configs.controlledRestSoNotifiableRoles);

          if(configs.hostSoNotifiableRoles) this.notifications.so.hostSoNotifiableRoles = JSON.parse(configs.hostSoNotifiableRoles);
          if(configs.approvedHostSoNotifiableRoles) this.notifications.so.approvedHostSoNotifiableRoles = JSON.parse(configs.approvedHostSoNotifiableRoles);
          if(configs.controlledHostSoNotifiableRoles) this.notifications.so.controlledHostSoNotifiableRoles = JSON.parse(configs.controlledHostSoNotifiableRoles);
 
          if(configs.gsSoNotifiableRoles) this.notifications.so.gsSoNotifiableRoles = JSON.parse(configs.gsSoNotifiableRoles);
          if(configs.approvedGsSoNotifiableRoles) this.notifications.so.approvedGsSoNotifiableRoles = JSON.parse(configs.approvedGsSoNotifiableRoles);
          if(configs.controlledGsSoNotifiableRoles) this.notifications.so.controlledGsSoNotifiableRoles = JSON.parse(configs.controlledGsSoNotifiableRoles);
 
          if(configs.thrdSoNotifiableRoles) this.notifications.so.thrdSoNotifiableRoles = JSON.parse(configs.thrdSoNotifiableRoles);
          if(configs.approvedThrdSoNotifiableRoles) this.notifications.so.approvedThrdSoNotifiableRoles = JSON.parse(configs.approvedThrdSoNotifiableRoles);
          if(configs.controlledThrdSoNotifiableRoles) this.notifications.so.controlledThrdSoNotifiableRoles = JSON.parse(configs.controlledThrdSoNotifiableRoles);
 
          if(configs.bookingNotifiableRoles) this.notifications.hosting.bookingNotifiableRoles = JSON.parse(configs.bookingNotifiableRoles);
          if(configs.approvedBookingNotifiableRoles) this.notifications.hosting.approvedBookingNotifiableRoles = JSON.parse(configs.approvedBookingNotifiableRoles);
          if(configs.rejectedBookingNotifiableRoles) this.notifications.hosting.rejectedBookingNotifiableRoles = JSON.parse(configs.rejectedBookingNotifiableRoles);
          if(configs.checkinBookingNotifiableRoles) this.notifications.hosting.checkinBookingNotifiableRoles = JSON.parse(configs.checkinBookingNotifiableRoles);
          if(configs.checkoutdBookingNotifiableRoles) this.notifications.hosting.checkoutdBookingNotifiableRoles = JSON.parse(configs.checkoutdBookingNotifiableRoles);
 
          if(configs.attNotifiableRoles) this.notifications.invoicing.attNotifiableRoles = JSON.parse(configs.attNotifiableRoles);
          if(configs.approvedAttNotifiableRoles) this.notifications.invoicing.approvedAttNotifiableRoles = JSON.parse(configs.approvedAttNotifiableRoles);
          if(configs.blockedAttNotifiableRoles) this.notifications.invoicing.blockedAttNotifiableRoles = JSON.parse(configs.blockedAttNotifiableRoles);
          if(configs.closedAttNotifiableRoles) this.notifications.invoicing.closedAttNotifiableRoles = JSON.parse(configs.closedAttNotifiableRoles);
 
          if(configs.invNotifiableRoles ) this.notifications.invoicing.invNotifiableRoles = JSON.parse(configs.invNotifiableRoles);
          if(configs.approvedInvNotifiableRoles ) this.notifications.invoicing.approvedInvNotifiableRoles = JSON.parse(configs.approvedInvNotifiableRoles);
          if(configs.cancelledInvNotifiableRoles ) this.notifications.invoicing.cancelledInvNotifiableRoles = JSON.parse(configs.cancelledInvNotifiableRoles);
          if(configs.invPaymentRegNotifiableRoles ) this.notifications.invoicing.invPaymentRegNotifiableRoles = JSON.parse(configs.invPaymentRegNotifiableRoles);
          if(configs.closedInvNotifiableRoles ) this.notifications.invoicing.closedInvNotifiableRoles = JSON.parse(configs.closedInvNotifiableRoles);

        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {});
    },
    submitContractNotifsConfig() {
      this.submitted = true;
      // this.$v.config.contract.$touch();
      var loader = this.$loading.show();
      this.$http
        .post("/system/settings/store", this.notifications.contract)
        .then((res) => {
          var status = res.data.original.status;
          loader.hide();
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.populateCompanySettings();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
      // if (!this.$v.config.contract.$invalid) {
      // }
    },

    submitSoNotifsConfig(){
        this.submitted = true;
      // this.$v.config.contract.$touch();
      var loader = this.$loading.show();
      this.$http
        .post("/system/settings/store", this.notifications.so)
        .then((res) => {
          var status = res.data.original.status;
          loader.hide();
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.populateCompanySettings();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
      // if (!this.$v.config.contract.$invalid) {
      // }
    },

    submitHostingNotifsConfig(){
        this.submitted = true;
      // this.$v.config.contract.$touch();
      var loader = this.$loading.show();
      this.$http
        .post("/system/settings/store", this.notifications.hosting)
        .then((res) => {
          var status = res.data.original.status;
          loader.hide();
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.populateCompanySettings();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
      // if (!this.$v.config.contract.$invalid) {
      // }
    },

    submitRequestsNotifsConfig() {
      this.submitted = true;
      // this.$v.config.hosting.$touch();
      var loader = this.$loading.show();
      this.$http
        .post("/system/settings/store", this.notifications.requests)
        .then((res) => {
          var status = res.data.original.status;
          loader.hide();
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.populateCompanySettings();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
      // if (!this.$v.config.hosting.$invalid) {
      // }
    },

    submitOdsConfig() {
      this.submitted = true;
      this.$v.config.ods.$touch();
      if (!this.$v.config.ods.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.ods)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    submitInvoicingNotifsConfig(){
        this.submitted = true;
      // this.$v.config.hosting.$touch();
      var loader = this.$loading.show();
      this.$http
        .post("/system/settings/store", this.notifications.invoicing)
        .then((res) => {
          var status = res.data.original.status;
          loader.hide();
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.populateCompanySettings();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    submitInvoicingConfig() {
      this.submitted = true;
      this.$v.config.invoicing.$touch();
      if (!this.$v.config.invoicing.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/store", this.config.invoicing)
          .then((res) => {
            var status = res.data.original.status;
            loader.hide();
            switch (status) {
              case 200:
                this.submitted = false;
                this.$toast.success(res.data.original.msg);
                this.populateCompanySettings();
                break;

              case 500:
                this.submitted = false;
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.submitted = false;
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    formSubmit() {},
  },
  data() {
    return {
      title: "Notifications & Workflow",
      submitted: false,
      showHelpModal: true,
      rolesList: [],
      notifications: {
        // contract Consommations
        contract: {
          ctrCnsmFirstAlertNotifiableRoles: "",
          ctrCnsmSecondAlertNotifiableRoles: "",
          ctrCnsmFinalAlertNotifiableRoles: "",
          // contract Consommations
          ctrEndDateFirstAlertNotifiableRoles: "",
          ctrEndDateSecondAlertNotifiableRoles: "",
          ctrEndDateFinalAlertNotifiableRoles: "",
        },

        so: {
          // Restauration
          restSoNotifiableRoles: "",
          approvedRestSoNotifiableRoles: "",
          controlledRestSoNotifiableRoles: "",

          // hosting
          hostSoNotifiableRoles: "",
          approvedHostSoNotifiableRoles: "",
          controlledHostSoNotifiableRoles: "",

          // gs
          gsSoNotifiableRoles: "",
          approvedGsSoNotifiableRoles: "",
          controlledGsSoNotifiableRoles: "",
          // 3d
          thrdSoNotifiableRoles: "",
          approvedThrdSoNotifiableRoles: "",
          controlledThrdSoNotifiableRoles: "",
        },

        hosting: {
          bookingNotifiableRoles: "",
          approvedBookingNotifiableRoles: "",
          rejectedBookingNotifiableRoles: "",
          checkinBookingNotifiableRoles: "",
          checkoutdBookingNotifiableRoles: "",
        },
        //maintenance request
        requests: {
          mrNotifiableRoles: "",
          approvedMrNotifiableRoles: "",
          rejectedMrNotifiableRoles: "",

          // maintenance order
          moNotifiableRoles: "",
          approvedMoNotifiableRoles: "",
          rejectedMoNotifiableRoles: "",

          srNotifiableRoles: "",
          approvedSrNotifiableRoles: "",
          rejectedSrNotifiableRoles: "",

          drNotifiableRoles: "",
          approvedDrNotifiableRoles: "",
          rejectedDrNotifiableRoles: "",

          tamrNotifiableRoles: "",
          approvedTamrNotifiableRoles: "",
          rejectedTamrNotifiableRoles: "",
        },

        invoicing: {
          // attachement
          attNotifiableRoles: "",
          approvedAttNotifiableRoles: "",
          blockedAttNotifiableRoles: "",
          closedAttNotifiableRoles: "",

          // supplier invoice
          invNotifiableRoles: "",
          approvedInvNotifiableRoles: "",
          cancelledInvNotifiableRoles: "",
          invPaymentRegNotifiableRoles: "",
          closedInvNotifiableRoles: "",
        },
      },

      items: [
        {
          text: "Paramètres",
          to: { name: "base.settings.index" },
        },
        {
          text: "Notifications & Workflow",
          active: false,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Modal @close="showHelpModal = false" v-model="showHelpModal" title="Help">
      <div class="row">
        <div class="col-lg-12">
          <b-alert show variant="info">
            <i class="mdi mdi-alert-circle-outline mr-2"></i>Comment définir Le
            flux d'informations ?
          </b-alert>
          <p class="card-text">
            Principalement dans cette version, le flux d'informations ici
            définit les personnes concerné par les notifications des différentes
            opérations.
          </p>
          <br />
          <ol>
            <li><p>Vous pouvez sélectionner un ou plusieurs rôles.</p></li>
            <li><p>Chaque rôle peut contenir un group d'utilisateurs.</p></li>
            <li>
              <p>
                Pour chaque rôle selectionné, chaque utilisateur sera informé
                quand une requête est crée.
              </p>
            </li>
            <li>
              <p>
                Les utilisateurs qui ont crée les opérations seront notifiés par
                défaut, avec leurs supérieurs directes.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </Modal>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab title="Contrat" active title-item-class="mb-2">
                <form @submit.prevent="submitContractNotifsConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Consommations</h4>
                      <hr />
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">1ère alerte est déclanchée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.contract
                                  .ctrCnsmFirstAlertNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">2ème alerte est déclanchée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.contract
                                  .ctrCnsmSecondAlertNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">3ème alerte est déclanchée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.contract
                                  .ctrCnsmFinalAlertNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Délais du contrat</h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">1ère alerte est déclanchée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.contract
                                  .ctrEndDateFirstAlertNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">2ème alerte est déclanchée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.contract
                                  .ctrEndDateSecondAlertNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">3ème alerte est déclanchée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.contract
                                  .ctrEndDateFinalAlertNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>

              <b-tab title="ODS" title-item-class="mb-2">
                <form @submit.prevent="submitSoNotifsConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Restauration</h4>
                      <hr />
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est généré</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.restSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est aprouvé</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.approvedRestSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Un ODS est contrôllé
                            </label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.controlledRestSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Hébergement</h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est générée</label>
                            <multiselect
                              :searchable="true"
                              v-model="notifications.so.hostSoNotifiableRoles"
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est approuvée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.approvedHostSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est contrôllé</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.controlledHostSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Espace Vert</h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est générée</label>
                            <multiselect
                              :searchable="true"
                              v-model="notifications.so.gsSoNotifiableRoles"
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est approuvé</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.approvedGsSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est contrôllé</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.controlledGsSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Opérations 3D</h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est générée</label>
                            <multiselect
                              :searchable="true"
                              v-model="notifications.so.thrdSoNotifiableRoles"
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est approuvée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.approvedThrdSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un ODS est contrôllé</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.so.controlledThrdSoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>

              <b-tab title="Hébergement" title-item-class="mb-2">
                <form @submit.prevent="submitHostingNotifsConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Réservations</h4>
                      <hr />
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une demande est généré</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.hosting.bookingNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une réservation est approuvée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.hosting.approvedBookingNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Une réservation est rejetée
                            </label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.hosting.rejectedBookingNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-md-4 mt-2">
                          <div class="form-grou">
                            <label for="">
                              CHECK-IN
                            </label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.hosting.checkinBookingNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-md-4 mt-2">
                          <div class="form-grou">
                            <label for="">
                              CHECK-OUT
                            </label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.hosting.checkoutdBookingNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>

              <b-tab title="Requêtes" title-item-class="mb-2">
                <!-- Requêtes Maintenance -->
                <form @submit.prevent="submitRequestsNotifsConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Requêtes Maintenance</h4>
                      <hr />
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est crée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.mrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est validée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.approvedMrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Une requête est rejetée
                            </label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.rejectedMrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Ordre de Maintenance</h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">un OM est crée</label>
                            <multiselect
                              :searchable="true"
                              v-model="notifications.requests.moNotifiableRoles"
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">un OM est approuvé</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.approvedMoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">un OM est rejeté</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.rejectedMoNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Prise en charge -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Requêtes Prise en charge</h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est crée</label>
                            <multiselect
                              :searchable="true"
                              v-model="notifications.requests.srNotifiableRoles"
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est approuvée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.approvedSrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est rejetée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.rejectedSrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Demande de dotations -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">
                        Requêtes Demande de dotations
                      </h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est crée</label>
                            <multiselect
                              :searchable="true"
                              v-model="notifications.requests.drNotifiableRoles"
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est approuvée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.approvedDrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est rejetée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.rejectedDrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- TAW MEAL -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">
                        Requêtes Demande de repas emporté
                      </h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est crée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests.tamrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est approuvée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests
                                  .approvedTamrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une requête est rejetée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.requests
                                  .rejectedTamrNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>

              <b-tab title="Facturation" title-item-class="mb-2">
                <form @submit.prevent="submitInvoicingNotifsConfig">
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Attachements</h4>
                      <hr />
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un attachement est généré</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.attNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Un attachement est aprouvé</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.approvedAttNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">
                              Un attachement est bloquée
                            </label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.blockedAttNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-md-4 mt-2">
                          <div class="form-grou">
                            <label for="">
                              Un attachement est clôturé
                            </label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.closedAttNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Ordre de maintenance Maintenance -->
                  <div class="row">
                    <div class="col-lg-12 ">
                      <h4 class="card-title mb-4">Factures prestataire</h4>
                      <h5>Qui sera notifié quand:</h5>
                      <div class="row">
                        <!-- <h6>Référence de document</h6> <br> -->
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une facture est générée</label>
                            <multiselect
                              :searchable="true"
                              v-model="notifications.invoicing.invNotifiableRoles"
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une facture est approuvée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.approvedInvNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grou">
                            <label for="">Une facture est annulée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.cancelledInvNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-md-4 mt-2">
                          <div class="form-grou">
                            <label for="">Une facture est payée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.invPaymentRegNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>

                        <div class="col-md-4 mt-2">
                          <div class="form-grou">
                            <label for="">Une facture est clôturée</label>
                            <multiselect
                              :searchable="true"
                              v-model="
                                notifications.invoicing.closedInvNotifiableRoles
                              "
                              track-by="id"
                              :multiple="true"
                              label="name"
                              :options="rolesList"
                              placeholder=""
                              :select-label="''"
                              :allow-empty="false"
                            >
                              <template
                                slot="singleType"
                                slot-scope="{ type }"
                                >{{ type.name }}</template
                              >
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary float-right">
                        <i class="fas fa-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>
              
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
